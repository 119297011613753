import login_img from "../assets/img/admin_login.svg";
import login_svg_img from "../assets/img/login_svg_img.svg";
import axios from "axios";
export { TextField } from "./TextField";
export { default as CustomModal } from "./customModal";

export const api = "https://t1.eonpayafrica.com/";
export const apiurl = api+"api/";
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //  "Authorization": `Bearer ${localStorage.getItem('token')}`
};

const allconstants = {
  login_img,
  login_svg_img,
  api,
  apiurl,
  axios,
  headers,
};

export default allconstants;
